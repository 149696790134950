/* tslint:disable */
/* eslint-disable */
/**
 * Haltian API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GeneralInfoResponse,
    GeneralInfoResponseFromJSON,
    GeneralInfoResponseToJSON,
    GetDeploymentGroupResponse,
    GetDeploymentGroupResponseFromJSON,
    GetDeploymentGroupResponseToJSON,
    ThingDeploymentGroupRequest,
    ThingDeploymentGroupRequestFromJSON,
    ThingDeploymentGroupRequestToJSON,
    ThingDeploymentGroupResponse,
    ThingDeploymentGroupResponseFromJSON,
    ThingDeploymentGroupResponseToJSON,
} from '../models';

export interface GeneralInfoRequest {
    thingId: string;
}

export interface GetDeploymentGroupRequest {
    thingId: string;
}

export interface SetDeploymentGroupRequest {
    thingId: string;
    thingDeploymentGroupRequest?: ThingDeploymentGroupRequest;
}

/**
 * 
 */
export class ThingsApi extends runtime.BaseAPI {

    /**
     * Gets general information of a Thing
     * Get general information of a Thing
     */
    async generalInfoRaw(requestParameters: GeneralInfoRequest): Promise<runtime.ApiResponse<GeneralInfoResponse>> {
        if (requestParameters.thingId === null || requestParameters.thingId === undefined) {
            throw new runtime.RequiredError('thingId','Required parameter requestParameters.thingId was null or undefined when calling generalInfo.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/things/{thingId}`.replace(`{${"thingId"}}`, encodeURIComponent(String(requestParameters.thingId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GeneralInfoResponseFromJSON(jsonValue));
    }

    /**
     * Gets general information of a Thing
     * Get general information of a Thing
     */
    async generalInfo(requestParameters: GeneralInfoRequest): Promise<GeneralInfoResponse> {
        const response = await this.generalInfoRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gets deployment group of a Thing
     * Gets deployment group of a Thing
     */
    async getDeploymentGroupRaw(requestParameters: GetDeploymentGroupRequest): Promise<runtime.ApiResponse<GetDeploymentGroupResponse>> {
        if (requestParameters.thingId === null || requestParameters.thingId === undefined) {
            throw new runtime.RequiredError('thingId','Required parameter requestParameters.thingId was null or undefined when calling getDeploymentGroup.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/things/{thingId}/group`.replace(`{${"thingId"}}`, encodeURIComponent(String(requestParameters.thingId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDeploymentGroupResponseFromJSON(jsonValue));
    }

    /**
     * Gets deployment group of a Thing
     * Gets deployment group of a Thing
     */
    async getDeploymentGroup(requestParameters: GetDeploymentGroupRequest): Promise<GetDeploymentGroupResponse> {
        const response = await this.getDeploymentGroupRaw(requestParameters);
        return await response.value();
    }

    /**
     * Sets deployment group for a Thing
     * Sets deployment group for a Thing
     */
    async setDeploymentGroupRaw(requestParameters: SetDeploymentGroupRequest): Promise<runtime.ApiResponse<ThingDeploymentGroupResponse>> {
        if (requestParameters.thingId === null || requestParameters.thingId === undefined) {
            throw new runtime.RequiredError('thingId','Required parameter requestParameters.thingId was null or undefined when calling setDeploymentGroup.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/things/{thingId}/group`.replace(`{${"thingId"}}`, encodeURIComponent(String(requestParameters.thingId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ThingDeploymentGroupRequestToJSON(requestParameters.thingDeploymentGroupRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ThingDeploymentGroupResponseFromJSON(jsonValue));
    }

    /**
     * Sets deployment group for a Thing
     * Sets deployment group for a Thing
     */
    async setDeploymentGroup(requestParameters: SetDeploymentGroupRequest): Promise<ThingDeploymentGroupResponse> {
        const response = await this.setDeploymentGroupRaw(requestParameters);
        return await response.value();
    }

}
