import { createStyles } from "@material-ui/core";
import theme from "../../../theme/theme";

export const styles = createStyles({
  content: {
    marginLeft: 320
  },

  infoTopWrapper: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between"
  },

  infoContainerBlock: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    marginTop: theme.spacing(2)
  },

  infoContainerId: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    marginTop: theme.spacing(1),
    color: "#828282"
  },

  logo: {
    height: "28px",
    width: "auto"
  }
});