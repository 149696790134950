import * as React from "react";
import { AppBar, Box, Toolbar, withStyles, WithStyles, IconButton, Typography, Button, Breadcrumbs, TextField, MenuItem } from "@material-ui/core";
import strings from "../../../localization/strings";
import { styles } from "./app-layout.styles";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../../resources/svg/Metatavu-M.svg"
import { KeycloakInstance } from "keycloak-js";
import { connect } from "react-redux";
import { ReduxState } from "../../../store";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  user?: string;
  selectedCustomer?: string;
  keycloak: KeycloakInstance;
  locale?: string;
  swapLanguage: (locale: string) => void;
}

/**
 * App layout component
 *
 * @param props component props
 */
const AppLayout: React.FC<Props> = ({
  classes,
  children,
  user,
  selectedCustomer,
  keycloak,
  locale,
  swapLanguage
}) => {
  return (
    <Box>
      <AppBar>
        <Toolbar>
          <Box className={ classes.titleArea }>
            <IconButton edge="start" color="inherit" aria-label="logo">
              <Logo className={ classes.logo } />
            </IconButton>
            <Breadcrumbs>
              <Link to="/" className={ classes.customers }>
                <Typography variant="h3"> 
                  { strings.header.customers }
                </Typography>
              </Link>
              { selectedCustomer &&
                <Typography variant="h2" color="primary">
                  { selectedCustomer }
                </Typography>
              }
            </Breadcrumbs>
          </Box>
          <Box>
          <TextField
            select
            className={ classes.languageSelect }
            value={ strings.getLanguage() }
            onChange={
              event => { 
                strings.setLanguage(event.target.value as string)
                swapLanguage(event.target.value as string);
              }
            }
          >
          {
            strings.getAvailableLanguages().map(language =>
              <MenuItem key={ language } value={ language }>
                { language }
              </MenuItem>
            )
          }
          </TextField>
            { user &&
              <Typography>
                { user }
              </Typography>
            }
            <Button 
              className={ classes.signOutButton }
              size="small"
              variant="text"
              onClick={ () => keycloak.logout() }
            >
              { strings.header.logOut }
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar/>
      <Box overflow="auto">
        { children }
      </Box>
    </Box>
  );
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 * @returns state from props
 */
const mapStateToProps = (state: ReduxState) => ({
  keycloak: state.auth.keycloak as KeycloakInstance
});

export default connect(mapStateToProps)(withStyles(styles)(AppLayout));
