import { createStyles } from "@material-ui/core";
import theme from "../../../theme/theme";

export const styles = createStyles({

  root: {
    display: "flex",
    height: "100vh",
    backgroundColor: theme.palette.background.default
  },

  titleArea: {
    display: "flex",
    alignItems: "center"
  },

  buttonGroup: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },

  languageSelect: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    color: "#fff",
    "& .MuiSelect-selectMenu": {
      textTransform: "uppercase"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: 0
    }
  },
  
  signOutButton: {
    fontWeight: "bold",
    root: {
      textSizeSmall: true
    }
  },

  customers: {
    textDecoration: "none"
  },
  
  logo: {
    width: "30px",
    height: "30px"
  }

});
