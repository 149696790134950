import { Configuration, DeploymentGroupsApi, DevicesApi } from "../generated/client";
import { CombinedAccessToken } from "../types";

/**
 * Utility class for loading api with predefined configuration
 */
export default class Api {

  /**
   * Gets initialized deployment groups api
   *
   * @param token access token
   * @returns initialized deployment groups api
   */
  public static getDeploymentGroupsApi(accessToken: CombinedAccessToken) {
    return new DeploymentGroupsApi(Api.getConfiguration(accessToken));
  }

  /**
   * Gets initialized devices api
   * 
   * @param accessToken access token
   * @returns initialized devices api
   */
  public static getDevicesApi(accessToken: CombinedAccessToken) {
    return new DevicesApi(Api.getConfiguration(accessToken));
  }

  /**
   * Gets api configuration
   *
   * @param accessToken keycloak access token
   */
  private static getConfiguration(accessToken: CombinedAccessToken) {
    return new Configuration({
      basePath: process.env.REACT_APP_API_BASE_PATH,
      accessToken: accessToken.token
    });
  }

}