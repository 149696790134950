import * as React from "react";

import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ReduxState } from "../../store";
import { login } from "../../actions/auth";

import { KeycloakInstance } from "keycloak-js";
import Keycloak from "keycloak-js";
import { CombinedAccessToken } from "../../types";
import { Auth } from "../../generated/haltian-client";
import HaltianApi from "../../api/haltian-api";

/**
 * Interface describing component properties
 */
interface Props {
  accessToken?: CombinedAccessToken;
  login: typeof login;
  children?: React.ReactNode;
}

/**
 * Interface describing component state
 */
interface State { }

/**
 * Component for keeping authentication token fresh
 */
class AccessTokenRefresh extends React.Component<Props, State> {

  private keycloak: KeycloakInstance;
  private timer?: NodeJS.Timer;
  private haltianTimer?: NodeJS.Timer;

  /**
   * Constructor
   *
   * @param props props
   */
  constructor(props: Props) {
    super(props);

    this.keycloak = Keycloak({
      url: process.env.REACT_APP_KEYCLOAK_URL,
      realm: process.env.REACT_APP_KEYCLOAK_REALM || "",
      clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || ""
    });

    this.state = { };
  }

  /**
   * Component did mount life cycle event
   */
  public componentDidMount = async () => {
    const auth = await this.keycloakInit();

    if (!auth) {
      window.location.reload();
    } else {
      const { token, tokenParsed } = this.keycloak;

      if (this.keycloak && tokenParsed && tokenParsed.sub && token) {
        this.keycloak.loadUserProfile();
        const haltianToken = await this.fetchHaltianToken();
        if (haltianToken) {
          this.props.login(this.keycloak, haltianToken);
        }
      }

      this.refreshAccessToken();

      this.timer = setInterval(() => {
        this.refreshAccessToken();
      }, 1000 * 60);

      this.haltianTimer = setInterval(() => {
        this.refreshHaltianAccessToken();
      }, 1000 * 60);

    }
  }

  /**
   * Component will unmount life cycle event
   */
  public componentWillUnmount = () => {
    if (this.timer) {
      clearInterval(this.timer);
    }
    if (this.haltianTimer) {
      clearInterval(this.haltianTimer);
    }
  }

  /**
   * Component render method
   */
  public render = () => {
    return this.props.accessToken ?
      this.props.children :
      null;
  }

  /**
   * Refreshes access token
   */
  private refreshAccessToken = async () => {
    try {
      const refreshed = await this.keycloak.updateToken(70);
      if (refreshed) {
        const { token, tokenParsed } = this.keycloak;

        if (tokenParsed && tokenParsed.sub && token) {
          const haltianToken = await this.fetchHaltianToken();
          if (haltianToken) {
            this.props.login(this.keycloak, haltianToken);
          }
        }
      }
    } catch (e) {
      this.setState({
        error: e
      });
    }
  }

  /**
   * Refreshes Haltian access token
   */
  private refreshHaltianAccessToken = async () => {
    try {
      const haltianToken = await this.fetchHaltianToken();
      haltianToken && this.props.login(this.keycloak, haltianToken);
    } catch (e) {
      this.setState({
        error: e
      });
    }
  }

  /**
   * Fetches haltian access token
   *
   * @returns Fetched haltian access token or undefined
   */
  private fetchHaltianToken = async (): Promise<string | undefined> => {
    const authApi = HaltianApi.getAuthApi();
    const auth: Auth = {
      client_id: process.env.REACT_APP_HALTIAN_API_CLIENT_ID || "",
      client_secret: process.env.REACT_APP_HALTIAN_API_CLIENT_SECRET || ""
    };

    try {
      const clientTokenResponse = await authApi.createClientAccessToken({ auth });
      if (!clientTokenResponse.data || !clientTokenResponse.data.token) {
        return;
      }

      return clientTokenResponse.data.token;
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Initializes Keycloak client
   */
  private keycloakInit = () => {
    return new Promise((resolve, reject) => {
      this.keycloak.init({ onLoad: "login-required", checkLoginIframe: false })
        .then(resolve)
        .catch(reject);
    });
  }
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
const mapStateToProps = (state: ReduxState) => ({
  accessToken: state.auth.accessToken
});

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  login: (keycloak: KeycloakInstance, haltianToken: string) => dispatch(login(keycloak, haltianToken))
});

export default connect(mapStateToProps, mapDispatchToProps)(AccessTokenRefresh);
