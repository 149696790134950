/* tslint:disable */
/* eslint-disable */
/**
 * Haltian API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Location,
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
} from './';

/**
 * Device GeneralInformation object
 * @export
 * @interface GeneralInformation
 */
export interface GeneralInformation {
    /**
     * Battery level of Thing
     * @type {number}
     * @memberof GeneralInformation
     */
    battery_level?: number;
    /**
     * Unix timestamp of last event
     * @type {number}
     * @memberof GeneralInformation
     */
    timestamp?: number;
    /**
     * 
     * @type {Location}
     * @memberof GeneralInformation
     */
    location?: Location;
    /**
     * Gateway tuid
     * @type {string}
     * @memberof GeneralInformation
     */
    gateway_tuid?: string;
    /**
     * Firmware version
     * @type {string}
     * @memberof GeneralInformation
     */
    version?: string;
}

export function GeneralInformationFromJSON(json: any): GeneralInformation {
    return GeneralInformationFromJSONTyped(json, false);
}

export function GeneralInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeneralInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'battery_level': !exists(json, 'battery_level') ? undefined : json['battery_level'],
        'timestamp': !exists(json, 'timestamp') ? undefined : json['timestamp'],
        'location': !exists(json, 'location') ? undefined : LocationFromJSON(json['location']),
        'gateway_tuid': !exists(json, 'gateway_tuid') ? undefined : json['gateway_tuid'],
        'version': !exists(json, 'version') ? undefined : json['version'],
    };
}

export function GeneralInformationToJSON(value?: GeneralInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'battery_level': value.battery_level,
        'timestamp': value.timestamp,
        'location': LocationToJSON(value.location),
        'gateway_tuid': value.gateway_tuid,
        'version': value.version,
    };
}


