import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import styles from "../../components/generic/map.styles";
import { MapContainer, Marker, TileLayer, useMap } from "react-leaflet";
import { Coordinates } from "../../generated/client";
import { MarkerIcon } from "../../resources/svg/marker";
import { MapProps } from "../../types/index";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  latitude: number;
  longitude: number;
}

/**
 * Inner component to display map marker
 * 
 * @param coordinates marker coordinates
 */
const MarkerLayer: React.FC<Coordinates> = ({ latitude, longitude }) => {

  return (
    <Marker
      position={[ latitude, longitude ]}
      icon={ MarkerIcon() }
    />
  );
}

/**
 * Inner component to display map layer with events
 * 
 * @param props layer properties
 */
const MapTileLayer: React.FC<MapProps> = ({ latitude, longitude, className }) => {
  const map = useMap();
  map.setView([ latitude, longitude ]);

  return (
    <TileLayer
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      className={ className }
    />
  );
}

/**
 * Map component
 *
 * @param props component properties
 */
const Map: React.FC<Props> = ({
  classes,
  latitude,
  longitude
}) => {

  const displayMap = React.useMemo(() => (
    <MapContainer
      style={{ width: 400, height: 400 }}
      zoom={ 13 }
      center={ { lat: latitude, lng: longitude } }
    >
      <MapTileLayer
        latitude={ latitude }
        longitude={ longitude }
        className={ classes.customTileLayer }
      />
      <MarkerLayer
        latitude={ latitude }
        longitude={ longitude }
      />
    </MapContainer>
    ),
    [ latitude, longitude, classes ]
  );

  return displayMap;
}

export default withStyles(styles)(Map);
