import { Box, CircularProgress, Divider, Drawer, IconButton, List, Toolbar, Typography, withStyles, WithStyles } from "@material-ui/core";
import * as React from "react";
import { styles } from "./sidebar-component.styles";
import strings from "../../../localization/strings";
import AddIcon from '@material-ui/icons/Add';
import { Device } from "../../../generated/client";
import DeviceItem from "../device-item/device-item";
import RefreshIcon from "@material-ui/icons/Refresh";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  devices: Device[];
  loading: boolean;
  addDialogToggle: () => void;
  onDeviceClick: (itemClicked: Device) => void;
  updateHaltianData: () => void;
}

/**
 * Side bar component
 */
const SideBar: React.FC<Props> = ({
  addDialogToggle,
  loading,
  updateHaltianData,
  devices,
  onDeviceClick
}) => {

  const deviceList = devices.map(device => 
    <DeviceItem
      key={ device.id ? device.id : "" }
      name={ device.name ? device.name : "" }
      status={ device.deviceInfo?.battery ? `${ device.deviceInfo.battery } %` : "? %" }
      selected={ false }
      onClick={ () => onDeviceClick(device) }
    />
  );

  return (
    <Drawer>
      <Toolbar />
      <Toolbar>
        <Typography>
          { strings.customer.devices }
        </Typography>
        <Box display="flex" alignItems="center">
          { loading &&
            <Box
              width="48px"
              height="48px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress size={ 16 } />
            </Box>
          }
          { !loading &&
            <IconButton
              color="primary"
              onClick={ updateHaltianData }
              title={ strings.customer.updateDevicesData }
            >
              <RefreshIcon />
            </IconButton>
          }
          <IconButton
            color="primary"
            onClick={ addDialogToggle }
          >
            <AddIcon />
          </IconButton>
        </Box>
      </Toolbar>
      <Divider />
      <Box overflow="auto">
        <List>
          { deviceList }
        </List>
      </Box>
    </Drawer>
  )
};

export default (withStyles(styles)(SideBar));
