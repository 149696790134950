import { createMuiTheme } from "@material-ui/core";
import logo from "../resources/svg/M-large.svg";

const defaultTheme = createMuiTheme();

export default createMuiTheme({

  palette: {
    type: "light",
    primary: {
      main: "#FF4336",
    },
    secondary: {
      main: "#000"
    },
    background: {
      paper: "#FFF",
      default: "#EEE"
    },
    text: {
      primary: "#121212"
    }
  },

  typography: {
    fontFamily: "'Source Code Pro', monospace",
    h1: {
      fontSize: 24
    },
    h2: {
      fontSize: 18,
      fontWeight: 700
    },
    h3: {
      fontSize: 18,
      fontWeight: 500
    },
    h4: {
      fontSize: 18
    },
    h5: {
      fontSize: 16
    },
    body1: {
      fontSize: 14
    },
    body2: {
      fontSize: 12
    }
  },

  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundImage: `url(${logo})`,
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundPosition: "50% 50%"
        },
        "::-webkit-scrollbar-track": {
          backgroundColor: defaultTheme.palette.grey[300]
        },
        "::-webkit-scrollbar": {
          height: 10,
          width: 10
        },
        "::-webkit-scrollbar-thumb": {
          backgroundColor: defaultTheme.palette.text.primary,
          border: "none"
        }
      }
    },
    MuiAppBar: {
      root: {
        zIndex: defaultTheme.zIndex.drawer + 1
      }
    },
    MuiToolbar: {
      root: {
        justifyContent: "space-between"
      }
    },
    MuiCard: {
      root: {
        position: "relative",
        minHeight: 200
      }
    },
    MuiCardHeader: {
      root: {
        zIndex: 1,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        padding: 8
      },
      avatar: {
        marginRight: 0
      }
    },
    MuiCardContent: {
      root: {
        textAlign: "center"
      }
    },
    MuiCardActionArea: {
      root: {
        height: "100%"
      }
    },
    MuiDialogTitle: {
      root: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #121212",
        fontSize: 18,
        fontWeight: 700
      }
    },
    MuiDialogContent: {
      root: {
        minWidth: 480,
        padding: 40,
      }
    },
    MuiDialogActions: {
      root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }
    },
    MuiDrawer: {
      root: {
        flexShrink: 0
      },
      paperAnchorDockedLeft: {
        borderRight: 0
      },
      paper: {
        width: 320,
      },
    },
    MuiTab: {
      root: {
        minHeight: 61
      },
    },
    MuiTabs: {
      flexContainer: {
        borderBottom: "4px solid #FF4336"
      },
      indicator: {
        height: 4
      }
    },
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: "#EEE",
        }
      },
      selected: {},
    },
    MuiButton: {
      root: {
        minWidth: 150
      },
      outlined: {
        border: "1px solid #121212",
        borderRadius: 20,
        margin: "0px 20px 0px 20px"
      },
      label: {
        fontWeight: 400
      },
      textPrimary: {
        textTransform: "none"
      },
      textSecondary: {
        textTransform: "none"
      },
    },
    MuiFab: {
      root: {
        backgroundColor: "transparent",
        zIndex: defaultTheme.zIndex.drawer + 1,
        boxShadow: "none"
      },
      primary: {
        color: "#FF4336",
        backgroundColor: "transparent",
        border: "1px solid #FF4336",
        "&:hover": {
          backgroundColor: "rgba(178, 46, 37, 0.2)"
        }
      }
    }
  },
  shape: {
    borderRadius: 0
  },
  props: {
    MuiIconButton: {
      color: "secondary"
    },
    MuiButton: {
      variant: "outlined",
      color: "primary",
    },
    MuiAppBar: {
      color: "inherit",
      variant: "outlined",
      position: "fixed"
    },
    MuiBreadcrumbs: {
      separator: "//"
    },
    MuiDrawer: {
      open: true,
      variant: "permanent"
    },
    MuiTabs: {
      centered: true,
      variant: "fullWidth"
    },
    MuiTab: {
      fullWidth: true, 
    },
    MuiTypography: {
      color: "textPrimary"
    },
    MuiListItem: {
      button: true
    },
    MuiCard: {
      elevation: 0
    },
    MuiPaper: {
      elevation: 0
    },
    MuiFab: {
      size: "large",
      color: "primary"
    }
  }
});
