import { Avatar, Card, CardActionArea, CardContent, CardHeader, CardMedia, IconButton, Typography, WithStyles, withStyles, Menu, MenuItem } from "@material-ui/core";
import * as React from "react";
import { styles } from "./customer-item.styles";
import MenuIcon from "../../../resources/svg/menu-icon";
import DeviceIcon from "../../../resources/svg/device-icon";
import strings from "../../../localization/strings";
/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  key: string | undefined;
  name: string | undefined;
  deviceCount: number;
  imgUrl?: string;
  onClick: () => void;
  onEditClick: () => void;
  onDeleteClick: () => void;
}

/**
 * Interface describing component state
 */
interface State {
  menuOpen: boolean;
  anchorEl: null | HTMLElement;
}

/**
 * Customer item component
 */
class CustomerItem extends React.Component<Props, State> {

  /**
   * Constructor
   *
   * @param props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      menuOpen: false,
      anchorEl: null
    };
  }

  /**
   * Component render
   */
  public render = () => {
    const {
      key,
      name,
      deviceCount,
      imgUrl,
      classes,
      onClick,
      onEditClick,
      onDeleteClick
    } = this.props;

    const { menuOpen, anchorEl } = this.state;

    return (
      <Card key={ key }>
        <CardHeader
          avatar={
            <Avatar
              className={ classes.deviceIcon }
              aria-label="devices"
            >
              <DeviceIcon />
            </Avatar>
          }
          title={ deviceCount }
          action={
            <IconButton onClick={ this.onMenuButtonClick } aria-label="settings">
              <MenuIcon />
            </IconButton>
          }
        />
        <Menu
          id="customer-menu"
          anchorEl={ anchorEl }
          keepMounted
          open={ menuOpen }
          onClose={ this.onMenuClose }
        >
          <MenuItem onClick={ onEditClick }>
            { strings.customerItem.edit }
          </MenuItem>
          <MenuItem onClick={ onDeleteClick }>
            { strings.customerItem.delete }
          </MenuItem>
        </Menu>
        <CardActionArea onClick={ onClick }>
          { imgUrl ?
            <CardMedia
              image={ imgUrl }
              title={`${ name } image`}
            />
            :
            <CardContent>
              <Typography variant="h1">
                { name }
              </Typography>
            </CardContent>
          }
        </CardActionArea>
      </Card>
    );
  }

  /**
   * Event handler for customer item menu button
   * 
   * @param event click event on customer item menu button
   */
  private onMenuButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      menuOpen: true,
      anchorEl: event.currentTarget
    });
  }

  /**
   * Event handler for closing customer item menu
   * 
   * @param event click event on customer item menu button
   */
  private onMenuClose = () => {
    this.setState({
      menuOpen: false,
      anchorEl: null
    });
  }
}

export default (withStyles(styles)(CustomerItem));
