import { SvgIcon, SvgIconProps } from "@material-ui/core";
import * as React from "react";

/**
 * Interface representing component properties
 */
interface Props extends SvgIconProps {}

/**
 * Render custom Menu icon
 */
const MenuIcon = (props: Props) => {
  return (
    <SvgIcon {...props}>
      <rect fill="#696969" x="3.9" y="7.46" width="9.14" height="2" transform="translate(-3.51 8.46) rotate(-45)"/>
      <rect fill="#696969" x="10.97" y="14.54" width="9.14" height="2" transform="translate(-6.44 15.54) rotate(-45)"/>
      <rect fill="#696969" x="7.43" y="11" width="9.14" height="2" transform="translate(-4.97 12) rotate(-45)"/>
    </SvgIcon>
  );
}
export default MenuIcon;