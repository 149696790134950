import { createStyles } from "@material-ui/core";

export const styles = createStyles({

  iconButtonWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    bottom: 0,
    right: 0
  }

});