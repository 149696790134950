import * as React from "react";
import { Box, Typography, WithStyles, withStyles, Fab, TextField } from "@material-ui/core";
import { styles } from "./home-screen.styles";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { KeycloakInstance } from "keycloak-js";
import { ReduxActions, ReduxState } from "../../../store";
import { CombinedAccessToken, ErrorContextType } from "../../../types";
import AppLayout from "../../layouts/app-layout";
import { DeploymentGroup } from "../../../generated/client";
import CustomerItem from "../../common/customer-item";
import strings from "../../../localization/strings";
import AddIcon from "@material-ui/icons/Add";
import Api from "../../../api/api";
import { History } from "history";
import GenericDialog from "../../generic/generic-dialog";
import produce from "immer";
import HaltianApi from "../../../api/haltian-api";
import { ErrorContext } from "../../common/error-context/error-handler";
import { setLocale } from "../../../actions/locale";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  history: History;
  keycloak?: KeycloakInstance;
  accessToken?: CombinedAccessToken;
  locale?: string;
}

/**
 * Interface describing component state
 */
interface State {
  error?: Error;
  deploymentGroups: DeploymentGroup[];
  selectedGroup?: DeploymentGroup;
  editing: boolean;
  customerDialogOpen: boolean;
  deleteCustomerDialogOpen: boolean;
  locale?: string;
}

/**
 * Home screen component
 */
class HomeScreen extends React.Component<Props, State> {

  static contextType: React.Context<ErrorContextType> = ErrorContext;

  /**
   * Constructor
   *
   * @param props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      deploymentGroups: [],
      customerDialogOpen: false,
      editing: false,
      deleteCustomerDialogOpen: false,
      locale: strings.getLanguage()
    };
  }

  /**
   * Component did mount life cycle handler
   */
  public componentDidMount = async () => {
    try {
      await this.fetchData();
    } catch (error) {
      this.context.setError(strings.error.whenFetchingData, error);
    }
  }

  /**
   * Component render
   */
  public render = () => {
    const { classes } = this.props;
    const { locale } = this.state;

    return (
      <AppLayout
        locale={ locale ?? strings.getLanguage() }
        swapLanguage={ this.swapLanguage }
      >
        <Box
          zIndex={ 1000 }
          p={ 4 }
          position="fixed"
          bottom={ 0 }
          right={ 0 }
        >
          <Fab
            color="primary"
            aria-label="add"
            onClick={ this.onAddCustomerClick }
          >
            <AddIcon />
          </Fab>
        </Box>
        <Box
          p={ 4 }
          className={ classes.content }
        >
          { this.renderDeploymentGroups() }
        </Box>
        { this.renderAddOrEditCustomerDialog() }
        { this.renderDeleteCustomerDialog() }
      </AppLayout>
    );
  }

  /**
   * Renders deployment groups
   */
  private renderDeploymentGroups = () => {
    const { deploymentGroups } = this.state;

    if (!deploymentGroups) {
      return (
        <Box>
          <Typography>
            { strings.home.noCustomersAvailable }
          </Typography>
        </Box>
      )
    }

    return deploymentGroups.map(group =>
      <CustomerItem
        key={ group.id }
        name={ group ? group.name : strings.home.noCustomerNameFound }
        deviceCount={ 6 }
        onClick={ () => this.onDeploymentGroupClick(group) }
        onEditClick={ () => this.onEditClick(group) }
        onDeleteClick={ () => this.onDeploymentGroupDeleteClick(group) }
      />
    );
  }

  /**
   * Renders Add customer dialog
   */
  private renderAddOrEditCustomerDialog = () => {
    const { customerDialogOpen, editing, selectedGroup } = this.state;

    if (!selectedGroup) {
      return null;
    }

    const title = editing ? strings.home.editCustomerDialog.title : strings.home.addCustomerDialog.title;
    const onConfirmAction = editing ? this.editCustomerClick : this.addCustomerClick;

    return(
      <GenericDialog
        cancelButtonText={ strings.genericDialog.cancel }
        positiveButtonText={ strings.genericDialog.confirm }
        title={ title }
        onConfirm={ onConfirmAction }
        onCancel={ this.onCloseOrCancelClick }
        open={ customerDialogOpen }
        error={ false }
        onClose={ this.onCloseOrCancelClick }
        disabled={ selectedGroup.name === "" }
      >
        { this.renderAddOrEditDialogContent() }
      </GenericDialog>
    );
  }

  /**
   * Renders add or edit dialog content
   */
  private renderAddOrEditDialogContent = () => {
    const { selectedGroup } = this.state;

    if (!selectedGroup) {
      return null;
    }

    return (
      <Box>
        <TextField 
          fullWidth
          name="name"
          label={ strings.home.addCustomerDialog.name }
          variant="outlined"
          onChange={ this.onNameChange }
          value={ selectedGroup.name || "" }
        />
      </Box>
    );
  }

  /**
   * Renders delete customer dialog
   */
  private renderDeleteCustomerDialog = () => {
    const { deleteCustomerDialogOpen, selectedGroup } = this.state;

    return(
      <GenericDialog
        cancelButtonText={ strings.genericDialog.cancel }
        positiveButtonText={ strings.genericDialog.confirm }
        title={ strings.home.deleteCustomerDialog.title }
        onConfirm={ this.deleteCustomerClick }
        onCancel={ this.onCloseOrCancelClick }
        open={ deleteCustomerDialogOpen }
        error={ false }
        onClose={ this.onCloseOrCancelClick }
      >
        <Typography>
          { strings.formatString(strings.home.deleteCustomerDialog.explanation, selectedGroup?.name ? selectedGroup?.name : "") }
        </Typography>
      </GenericDialog>
    );
  }

  /**
   * Event handler for closing or cancelling dialog
   */
  private onCloseOrCancelClick = () => {
    this.setState({
      customerDialogOpen: false,
      deleteCustomerDialogOpen: false,
      selectedGroup: undefined
    });
  }

  /**
   * Event handler for add customer button click
   */
  private onAddCustomerClick = () => {
    const newDeploymentGroup: DeploymentGroup = {
      name: "",
      haltianId: ""
    };

    this.setState({
      selectedGroup: newDeploymentGroup,
      customerDialogOpen: true,
      editing: false
    });
  }

  /**
   * Event handler for add customer click
   */
  private addCustomerClick = async () => {
    const { accessToken } = this.props;
    const { selectedGroup } = this.state;

    if (!selectedGroup || !accessToken) {
      return;
    }

    const haltianApi = HaltianApi.getGroupsApi(accessToken?.haltianToken);

    try {
      await haltianApi.createGroup({
        createDeploymentGroupRequest: {
          group_id: selectedGroup.haltianId,
          group_description: selectedGroup.name
        }
      });

    } catch (error) {
      this.context.setError(strings.error.whenAddingDeploymentGroup, error);
    }

    try {
      const deploymentGroupsApi = Api.getDeploymentGroupsApi(accessToken);
      const createdDeploymentGroup = await deploymentGroupsApi.createDeploymentGroup({
        deploymentGroup: selectedGroup
      });

      this.setState({
        deploymentGroups: [ ...this.state.deploymentGroups, createdDeploymentGroup ]
      });

    } catch (error) {

      console.error(error);
      error instanceof Response ?
        this.context.setError(`${strings.error.whenAddingDeploymentGroup}: ${error.status}`) :
        this.context.setError(strings.error.whenAddingDeploymentGroup);
    }
    

    this.onCloseOrCancelClick();
  }

  /**
   * Event handler for edit customer click
   */
  private editCustomerClick = async () => {
    const { accessToken } = this.props;
    const { selectedGroup, deploymentGroups } = this.state;

    if (!selectedGroup || !selectedGroup.id || !accessToken) {
      return;
    }

    try {
      const deploymentGroupsApi = Api.getDeploymentGroupsApi(accessToken);
      const updatedDeploymentGroup = await deploymentGroupsApi.updateDeploymentGroup({
        deploymentGroupId: selectedGroup.id,
        deploymentGroup: selectedGroup
      });

      const updateGroups = produce(deploymentGroups, draft => {
        const groupIndex = draft.findIndex(group => group.id === updatedDeploymentGroup.id);
        if (groupIndex > -1) {
          draft.splice(groupIndex, 1, updatedDeploymentGroup);
        }
      });

      this.setState({
        deploymentGroups: updateGroups,
        selectedGroup: undefined,
        editing: false,
        customerDialogOpen: false
      });

    } catch (error) {
      this.context.setError(strings.error.whenUpdatingDeploymentGroup, error);
    }
    
  }

  /**
   * Event handler for delete customer click
   */
  private deleteCustomerClick = async () => {
    const { accessToken } = this.props;
    const { selectedGroup, deploymentGroups } = this.state;

    if (!selectedGroup || !selectedGroup.id || !accessToken) {
      return;
    }

    try {
      const deploymentGroupsApi = Api.getDeploymentGroupsApi(accessToken);
      await deploymentGroupsApi.deleteDeploymentGroup({
        deploymentGroupId: selectedGroup.id
      });

      this.setState({
        deploymentGroups: deploymentGroups.filter(group => group.id !== selectedGroup.id),
        selectedGroup: undefined,
        deleteCustomerDialogOpen: false
      });

    } catch (error) {
      this.context.setError(strings.error.whenDeletingDeploymentGroup, error);
    }
    
  }

  /**
   * Event handler for deployment group click
   *
   * @param group clicked group
   */
  private onDeploymentGroupClick = (group: DeploymentGroup) => {
    const { history } = this.props;

    if (!group.id) {
      return;
    }

    history.push(`/deploymentGroup/${group.id}`);
  }

  /**
   * Event handler for on edit click
   *
   * @param group selected deployment group
   */
  private onEditClick = (group: DeploymentGroup) => {
    this.setState({
      selectedGroup: group,
      customerDialogOpen: true,
      editing: true
    });
  }

  /**
   * Event handler for deployment group delete click
   *
   * @param group clicked group
   */
  private onDeploymentGroupDeleteClick = (group: DeploymentGroup) => {
    if (!group.id) {
      return;
    }

    this.setState({
      deleteCustomerDialogOpen: true,
      selectedGroup: group
    });
  }

  /**
   * Event handler for name change
   *
   * @param event React change event
   */
  private onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { selectedGroup } = this.state;
    const { value } = event.target;

    if (!selectedGroup) {
      return;
    }

    const tempGroup = { ...selectedGroup };
    tempGroup.name = value;
    tempGroup.haltianId = value.replaceAll(" ", "").toLowerCase();

    this.setState({
      selectedGroup: tempGroup
    });
  }

  /**
   * Provides language swap functionality for the page
   * 
   * @param locale locale
   */
  private swapLanguage = (locale: string) => {
    strings.setLanguage(locale);
    this.setState({ locale: locale });
  }

  /**
   * Fetch deployment group data from API
   */
  private fetchData = async () => {
    const { accessToken } = this.props;

    if (!accessToken) {
      return;
    }

    try {
      const deploymentGroupsApi = Api.getDeploymentGroupsApi(accessToken);
      const deploymentGroups = await deploymentGroupsApi.listDeploymentGroups();
      this.setState({
        deploymentGroups
      });
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 * @returns state from props
 */
const mapStateToProps = (state: ReduxState) => ({
  accessToken: state.auth.accessToken,
  keycloak: state.auth.keycloak,
  locale: state.locale.locale
});

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
const mapDispatchToProps = (dispatch: Dispatch<ReduxActions>) => ({
  setLocale: (locale: string) => dispatch(setLocale(locale))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(HomeScreen));
