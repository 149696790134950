/* tslint:disable */
/* eslint-disable */
/**
 * Haltian IoT API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DeploymentGroup,
    DeploymentGroupFromJSON,
    DeploymentGroupToJSON,
} from '../models';

export interface CreateDeploymentGroupRequest {
    deploymentGroup: DeploymentGroup;
}

export interface DeleteDeploymentGroupRequest {
    deploymentGroupId: string;
}

export interface FindDeploymentGroupRequest {
    deploymentGroupId: string;
}

export interface UpdateDeploymentGroupRequest {
    deploymentGroup: DeploymentGroup;
    deploymentGroupId: string;
}

/**
 * 
 */
export class DeploymentGroupsApi extends runtime.BaseAPI {

    /**
     * Creates new deployment group
     * Create a deployment group.
     */
    async createDeploymentGroupRaw(requestParameters: CreateDeploymentGroupRequest): Promise<runtime.ApiResponse<DeploymentGroup>> {
        if (requestParameters.deploymentGroup === null || requestParameters.deploymentGroup === undefined) {
            throw new runtime.RequiredError('deploymentGroup','Required parameter requestParameters.deploymentGroup was null or undefined when calling createDeploymentGroup.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/deploymentGroups`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeploymentGroupToJSON(requestParameters.deploymentGroup),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DeploymentGroupFromJSON(jsonValue));
    }

    /**
     * Creates new deployment group
     * Create a deployment group.
     */
    async createDeploymentGroup(requestParameters: CreateDeploymentGroupRequest): Promise<DeploymentGroup> {
        const response = await this.createDeploymentGroupRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes deployment group
     * Deletes a deployment group.
     */
    async deleteDeploymentGroupRaw(requestParameters: DeleteDeploymentGroupRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.deploymentGroupId === null || requestParameters.deploymentGroupId === undefined) {
            throw new runtime.RequiredError('deploymentGroupId','Required parameter requestParameters.deploymentGroupId was null or undefined when calling deleteDeploymentGroup.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/deploymentGroups/{deploymentGroupId}`.replace(`{${"deploymentGroupId"}}`, encodeURIComponent(String(requestParameters.deploymentGroupId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes deployment group
     * Deletes a deployment group.
     */
    async deleteDeploymentGroup(requestParameters: DeleteDeploymentGroupRequest): Promise<void> {
        await this.deleteDeploymentGroupRaw(requestParameters);
    }

    /**
     * Finds a deployment group by id
     * Find a deployment group.
     */
    async findDeploymentGroupRaw(requestParameters: FindDeploymentGroupRequest): Promise<runtime.ApiResponse<DeploymentGroup>> {
        if (requestParameters.deploymentGroupId === null || requestParameters.deploymentGroupId === undefined) {
            throw new runtime.RequiredError('deploymentGroupId','Required parameter requestParameters.deploymentGroupId was null or undefined when calling findDeploymentGroup.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/deploymentGroups/{deploymentGroupId}`.replace(`{${"deploymentGroupId"}}`, encodeURIComponent(String(requestParameters.deploymentGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DeploymentGroupFromJSON(jsonValue));
    }

    /**
     * Finds a deployment group by id
     * Find a deployment group.
     */
    async findDeploymentGroup(requestParameters: FindDeploymentGroupRequest): Promise<DeploymentGroup> {
        const response = await this.findDeploymentGroupRaw(requestParameters);
        return await response.value();
    }

    /**
     * Lists deploymentGroups
     * List deploymentGroups.
     */
    async listDeploymentGroupsRaw(): Promise<runtime.ApiResponse<Array<DeploymentGroup>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/deploymentGroups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DeploymentGroupFromJSON));
    }

    /**
     * Lists deploymentGroups
     * List deploymentGroups.
     */
    async listDeploymentGroups(): Promise<Array<DeploymentGroup>> {
        const response = await this.listDeploymentGroupsRaw();
        return await response.value();
    }

    /**
     * Updates deployment group
     * Updates a deployment group.
     */
    async updateDeploymentGroupRaw(requestParameters: UpdateDeploymentGroupRequest): Promise<runtime.ApiResponse<DeploymentGroup>> {
        if (requestParameters.deploymentGroup === null || requestParameters.deploymentGroup === undefined) {
            throw new runtime.RequiredError('deploymentGroup','Required parameter requestParameters.deploymentGroup was null or undefined when calling updateDeploymentGroup.');
        }

        if (requestParameters.deploymentGroupId === null || requestParameters.deploymentGroupId === undefined) {
            throw new runtime.RequiredError('deploymentGroupId','Required parameter requestParameters.deploymentGroupId was null or undefined when calling updateDeploymentGroup.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/deploymentGroups/{deploymentGroupId}`.replace(`{${"deploymentGroupId"}}`, encodeURIComponent(String(requestParameters.deploymentGroupId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DeploymentGroupToJSON(requestParameters.deploymentGroup),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DeploymentGroupFromJSON(jsonValue));
    }

    /**
     * Updates deployment group
     * Updates a deployment group.
     */
    async updateDeploymentGroup(requestParameters: UpdateDeploymentGroupRequest): Promise<DeploymentGroup> {
        const response = await this.updateDeploymentGroupRaw(requestParameters);
        return await response.value();
    }

}
