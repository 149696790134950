import * as React from "react";

import { Provider } from "react-redux";
import { createStore } from "@reduxjs/toolkit";
import { ReduxActions, ReduxState, rootReducer } from "../store";
import * as immer from "immer";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { responsiveFontSizes, ThemeProvider, CssBaseline } from "@material-ui/core";
import tavujokiTheme from "../theme/theme";
import HomeScreen from "./screens/home";
import CustomerScreen from "./screens/customer";
import AccessTokenRefresh from "./containers/access-token-refresh";
import ErrorHandler from "./common/error-context/error-handler";

/**
 * Initialize Redux store
 */
const store = createStore<ReduxState, ReduxActions, any, any>(rootReducer);

/**
 * Material UI automated responsive font sizes
 */
const theme = responsiveFontSizes(tavujokiTheme);

/**
 * App component
 */
const App: React.FC = () => {
  React.useEffect(() => {
    immer.enableAllPlugins();
  }, []);

  return (
    <ThemeProvider theme={ theme }>
      <CssBaseline />
      <Provider store={ store }>
        <AccessTokenRefresh>
          <ErrorHandler>
            <BrowserRouter>
              <Switch>
                <Route
                  path="/"
                  exact={ true }
                  render={({ history }) => (
                    <HomeScreen
                      history={ history }
                    />
                  )}
                />
                <Route
                  path="/deploymentGroup/:id"
                  exact={ true }
                  render={({ history, match }) => (
                    <CustomerScreen
                      history={ history }
                      deploymentGroupId={ match.params.id }
                    />
                  )}
                />
              </Switch>
            </BrowserRouter>
          </ErrorHandler>
        </AccessTokenRefresh>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
