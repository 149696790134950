import LocalizedStrings, { LocalizedStringsMethods } from "localized-strings";

/**
 * Interface describing localized strings
 */
export interface IStrings extends LocalizedStringsMethods {
  comingSoon: string;

  errorTitle: string;
  
  /**
   * Translations for header component
   */
  header: {
    logOut: string;
    customers: string;
  };
  
  /**
   * Translations related to generic-dialog component
   */
  genericDialog: {
    cancel: string;
    confirm: string;
  }

  /**
   * Translations related to customer item component
   */
  customerItem: {
    delete: string;
    edit: string;
  }

  /**
   * Translations for home screen
   */
  home: {
    addCustomer: string;
    noCustomerNameFound: string;
    noCustomersAvailable: string;
    addCustomerDialog: {
      title: string;
      name: string;
    }
    editCustomerDialog: {
      title: string,
    }
    deleteCustomerDialog: {
      title: string;
      explanation: string;
    }
  };

  /**
   * Translations for customer screen
   */
  customer: {
    devices: string;
    addDevices: string;
    add: string;
    battery: string;
    cancel: string;
    deleteDevice: string,
    deleteDeviceConfirmText: string,
    deviceInfo: string;
    id: string;
    sendCommand: string;
    location: string;
    messageQueries: string;
    noDataAvailable: string;
    noDeviceSelected: string;
    updated: string;
    yes: string;
    updateDevicesData: string;
  };

  /**
   * Translations for displaying errors
   */
  error: {
    whenAddingDevice: string;
    whenAddingDeploymentGroup: string;
    whenDeletingDevice: string;
    whenDeletingDeploymentGroup: string;
    whenFetchingData: string;
    whenUpdatingSettings: string;
    whenUpdatingDevice: string;
    whenUpdatingDeploymentGroup: string;
  };

}

const strings: IStrings = new LocalizedStrings({
  en: require("./en.json"),
  fi: require("./fi.json")
});

export default strings;
