/* tslint:disable */
/* eslint-disable */
/**
 * Haltian IoT API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Deployment group object
 * @export
 * @interface DeploymentGroup
 */
export interface DeploymentGroup {
    /**
     * Deployment group ID
     * @type {string}
     * @memberof DeploymentGroup
     */
    id?: string;
    /**
     * Haltian deployment group ID
     * @type {string}
     * @memberof DeploymentGroup
     */
    haltianId: string;
    /**
     * Customer name
     * @type {string}
     * @memberof DeploymentGroup
     */
    name: string;
}

export function DeploymentGroupFromJSON(json: any): DeploymentGroup {
    return DeploymentGroupFromJSONTyped(json, false);
}

export function DeploymentGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeploymentGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'haltianId': json['haltianId'],
        'name': json['name'],
    };
}

export function DeploymentGroupToJSON(value?: DeploymentGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'haltianId': value.haltianId,
        'name': value.name,
    };
}


