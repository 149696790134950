/* tslint:disable */
/* eslint-disable */
/**
 * Haltian API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClientToken,
    ClientTokenFromJSON,
    ClientTokenFromJSONTyped,
    ClientTokenToJSON,
} from './';

/**
 * Client token response object
 * @export
 * @interface ClientTokenResponse
 */
export interface ClientTokenResponse {
    /**
     * 
     * @type {ClientToken}
     * @memberof ClientTokenResponse
     */
    data?: ClientToken;
}

export function ClientTokenResponseFromJSON(json: any): ClientTokenResponse {
    return ClientTokenResponseFromJSONTyped(json, false);
}

export function ClientTokenResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientTokenResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ClientTokenFromJSON(json['data']),
    };
}

export function ClientTokenResponseToJSON(value?: ClientTokenResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ClientTokenToJSON(value.data),
    };
}


