import { createStyles } from "@material-ui/core";

export default createStyles({

  customTileLayer: {
    filter: "grayscale(1)"
  },

  mapRoot: {
    width: "100%",
    height: 360
  },

  mapContainer: {
    backgroundColor: "#FFFFFF",
    width: "100%",
    height: "100%"
  }

});