/* tslint:disable */
/* eslint-disable */
/**
 * Haltian API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Auth,
    AuthFromJSON,
    AuthToJSON,
    ClientTokenResponse,
    ClientTokenResponseFromJSON,
    ClientTokenResponseToJSON,
} from '../models';

export interface CreateClientAccessTokenRequest {
    auth?: Auth;
}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     * Creates client access token
     * Create client access token.
     */
    async createClientAccessTokenRaw(requestParameters: CreateClientAccessTokenRequest): Promise<runtime.ApiResponse<ClientTokenResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/auth/client-token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthToJSON(requestParameters.auth),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientTokenResponseFromJSON(jsonValue));
    }

    /**
     * Creates client access token
     * Create client access token.
     */
    async createClientAccessToken(requestParameters: CreateClientAccessTokenRequest): Promise<ClientTokenResponse> {
        const response = await this.createClientAccessTokenRaw(requestParameters);
        return await response.value();
    }

}
