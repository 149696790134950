/* tslint:disable */
/* eslint-disable */
/**
 * Haltian API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateDeploymentGroupRequest,
    CreateDeploymentGroupRequestFromJSON,
    CreateDeploymentGroupRequestToJSON,
    CreateDeploymentGroupResponse,
    CreateDeploymentGroupResponseFromJSON,
    CreateDeploymentGroupResponseToJSON,
} from '../models';

export interface CreateGroupRequest {
    createDeploymentGroupRequest?: CreateDeploymentGroupRequest;
}

/**
 * 
 */
export class GroupsApi extends runtime.BaseAPI {

    /**
     * Creates new deployment group to Haltian
     * Creates new deployment group to Haltian
     */
    async createGroupRaw(requestParameters: CreateGroupRequest): Promise<runtime.ApiResponse<CreateDeploymentGroupResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDeploymentGroupRequestToJSON(requestParameters.createDeploymentGroupRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateDeploymentGroupResponseFromJSON(jsonValue));
    }

    /**
     * Creates new deployment group to Haltian
     * Creates new deployment group to Haltian
     */
    async createGroup(requestParameters: CreateGroupRequest): Promise<CreateDeploymentGroupResponse> {
        const response = await this.createGroupRaw(requestParameters);
        return await response.value();
    }

}
