import { AuthApi, Configuration } from "../generated/haltian-client";
import { GroupsApi } from "../generated/haltian-client/apis/GroupsApi";
import { ThingsApi } from "../generated/haltian-client/apis/ThingsApi";

/**
 * Utility class for loading api with predefined configuration
 */
export default class HaltianApi {

  /**
   * Gets initialized auth api
   *
   * @param token access token
   * @returns initialized auth api
   */
  public static getAuthApi(haltianToken?: string) {
    return new AuthApi(HaltianApi.getConfiguration(haltianToken));
  }

  /**
   * Gets initialized groups api
   *
   * @param token access token
   * @returns initialized groups api
   */
  public static getGroupsApi(haltianToken?: string) {
    return new GroupsApi(HaltianApi.getConfiguration(haltianToken));
  }

  /**
   * Gets initialized things api
   *
   * @param token access token
   * @returns initialized things api
   */
  public static getThingsApi(haltianToken?: string) {
    return new ThingsApi(HaltianApi.getConfiguration(haltianToken));
  }

  /**
   * Gets api configuration
   */
  private static getConfiguration(haltianToken?: string) {
    return new Configuration({
      basePath: process.env.REACT_APP_HALTIAN_API_BASE_PATH_ID,
      accessToken: haltianToken
    });
  }

}