import L from "leaflet";

export const MarkerIcon = () => {
  const svgIcon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 32"><defs><style>.a{fill:#FF4336;}.b{fill:#FFFFFF;}</style></defs><path class="a" d="M23.94,13.16A11.12,11.12,0,0,0,24,12,12,12,0,0,0,0,12a11.12,11.12,0,0,0,.06,1.16c0,.05,0,.1,0,.15C1,22.85,12,32,12,32s11-9.15,11.93-18.69C23.93,13.26,23.94,13.21,23.94,13.16Z"/><circle class="b" cx="12" cy="12" r="5"/></svg>`;

  return new L.DivIcon({
    iconAnchor: [ 16, 16 ],
    html: svgIcon,
    iconSize: [ 32, 32 ],
    className: "customIcon"
  });
}