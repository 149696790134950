/* tslint:disable */
/* eslint-disable */
/**
 * Haltian API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ThingDeploymentGroup,
    ThingDeploymentGroupFromJSON,
    ThingDeploymentGroupFromJSONTyped,
    ThingDeploymentGroupToJSON,
} from './';

/**
 * Thing group response object
 * @export
 * @interface ThingDeploymentGroupResponse
 */
export interface ThingDeploymentGroupResponse {
    /**
     * 
     * @type {ThingDeploymentGroup}
     * @memberof ThingDeploymentGroupResponse
     */
    data?: ThingDeploymentGroup;
}

export function ThingDeploymentGroupResponseFromJSON(json: any): ThingDeploymentGroupResponse {
    return ThingDeploymentGroupResponseFromJSONTyped(json, false);
}

export function ThingDeploymentGroupResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ThingDeploymentGroupResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ThingDeploymentGroupFromJSON(json['data']),
    };
}

export function ThingDeploymentGroupResponseToJSON(value?: ThingDeploymentGroupResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ThingDeploymentGroupToJSON(value.data),
    };
}


