import { createStyles } from "@material-ui/core";
import theme from "../../../theme/theme";

export const styles = createStyles({

  content: {
    display: "grid",
    gridGap: theme.spacing(4),
    gridTemplateColumns: "1fr",
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "1fr 1fr"
    },
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "1fr 1fr 1fr"
    },
    [theme.breakpoints.up("lg")]: {
      gridTemplateColumns: "1fr 1fr 1fr 1fr"
    }
  },

  leftPanel: {
    height: "100%",
  },

  createDeviceGroupButton: {
    backgroundColor: theme.palette.primary.main
  },

  deleteGroupName: {
    fontWeight: "bold",
  }

});
