/* tslint:disable */
/* eslint-disable */
/**
 * Haltian API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Authentication object
 * @export
 * @interface Auth
 */
export interface Auth {
    /**
     * Client ID
     * @type {string}
     * @memberof Auth
     */
    client_id: string;
    /**
     * Client secret
     * @type {string}
     * @memberof Auth
     */
    client_secret: string;
}

export function AuthFromJSON(json: any): Auth {
    return AuthFromJSONTyped(json, false);
}

export function AuthFromJSONTyped(json: any, ignoreDiscriminator: boolean): Auth {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'client_id': json['client_id'],
        'client_secret': json['client_secret'],
    };
}

export function AuthToJSON(value?: Auth | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'client_id': value.client_id,
        'client_secret': value.client_secret,
    };
}


