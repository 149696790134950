import { ListItem, ListItemIcon, ListItemText, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import { styles } from "./device-item.styles";
import DeviceIcon from "../../../resources/svg/device-icon";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  name: string;
  status: string;
  selected?: boolean;
  onClick(): void;
}

/**
 * Interface describing component state
 */
interface State {
}

/**
 * Device list item component
 */
class DeviceItem extends React.Component<Props, State> {

  /**
   * Constructor
   *
   * @param props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
    };
  }

  /**
   * Component render
   */
  public render = () => {
    const {
      name,
      status,
      selected,
      onClick
    } = this.props;

    return (
      <ListItem
        selected={ selected }
        onClick={ onClick }
      >
        <ListItemIcon>
          <DeviceIcon selected={ selected } />
        </ListItemIcon>
        <ListItemText
          primary={ name }
          secondary={ status }
        />
      </ListItem>
    );
  }
}

export default (withStyles(styles)(DeviceItem));