/* tslint:disable */
/* eslint-disable */
/**
 * Haltian API-spec
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GeneralInformation,
    GeneralInformationFromJSON,
    GeneralInformationFromJSONTyped,
    GeneralInformationToJSON,
} from './';

/**
 * General info response object
 * @export
 * @interface GeneralInfoResponse
 */
export interface GeneralInfoResponse {
    /**
     * 
     * @type {GeneralInformation}
     * @memberof GeneralInfoResponse
     */
    data?: GeneralInformation;
}

export function GeneralInfoResponseFromJSON(json: any): GeneralInfoResponse {
    return GeneralInfoResponseFromJSONTyped(json, false);
}

export function GeneralInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeneralInfoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : GeneralInformationFromJSON(json['data']),
    };
}

export function GeneralInfoResponseToJSON(value?: GeneralInfoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': GeneralInformationToJSON(value.data),
    };
}


