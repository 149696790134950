import { Box, Button, Checkbox, FormControlLabel, FormGroup, Paper, Typography, WithStyles, withStyles } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { KeycloakInstance } from "keycloak-js";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import strings from "../../../localization/strings";
import { ReduxActions, ReduxState } from "../../../store";
import { CombinedAccessToken, ErrorContextType } from "../../../types";
import AppLayout from "../../layouts/app-layout/app-layout";
import { styles } from "./customer-screen.styles";
import { History } from "history";
import { DeploymentGroup, Device } from "../../../generated/client";
import Api from "../../../api/api";
import GenericDialog from "../../generic/generic-dialog";
import theme from "../../../theme/theme";
import Map from "../../generic/map"
import produce from "immer";
import { ReactComponent as Logo } from "../../../resources/svg/Haltian-logo.svg"
import HaltianApi from "../../../api/haltian-api";
import { ThingDeploymentGroupResponse } from "../../../generated/haltian-client";
import { ErrorContext } from "../../common/error-context/error-handler";
import moment from "moment";
import { setLocale } from "../../../actions/locale";
import SideBar from "../../common/sidebar-component/sidebar-component";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  deploymentGroupId: string;
  history: History;
  keycloak: KeycloakInstance;
  accessToken?: CombinedAccessToken;
  locale?: string;
}

/**
 * Interface describing component state
 */
interface State {
  error?: Error;
  selectedDeploymentGroup?: DeploymentGroup;
  selectedDevice?: Device;
  allDevices: Device[];
  addDeviceDialog: boolean;
  checkBoxedDevices: Device[];
  removeDeviceDialog: boolean;
  locale?: string;
  loading: boolean;
}

/**
 * Customer screen component
 */
class CustomerScreen extends React.Component<Props, State> {

  static contextType: React.Context<ErrorContextType> = ErrorContext;

  /**
   * Constructor
   *
   * @param props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      allDevices: [],
      addDeviceDialog: false,
      checkBoxedDevices: [],
      removeDeviceDialog: false,
      locale: strings.getLanguage(),
      loading: false
    };
  }

  /**
   * Component did mount life cycle handler
   */
  public componentDidMount = async () => {
    try {
      await this.fetchData();
    } catch (error) {
      this.context.setError(strings.error.whenFetchingData, error);
    }
    
  }

  /**
   * Component render
   */
  public render = () => {
    const { classes } = this.props;
    const { selectedDeploymentGroup, selectedDevice, locale } = this.state;

    if (!selectedDevice) {
      return (
        <AppLayout
          selectedCustomer={ selectedDeploymentGroup?.name ? selectedDeploymentGroup?.name : selectedDeploymentGroup?.id ?? "" }
          locale={ locale ?? "en" }
          swapLanguage={ this.swapLanguage }
        >
          { this.renderAddDeviceDialog () }
          { this.renderRemoveDeviceDialog () }
          { this.leftSideBar() }
          <Box
            p={ 4 }
            className={ classes.content }
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="90vh"
          >
            <Typography variant="h1">
              { strings.customer.noDeviceSelected }
            </Typography>
          </Box>
        </AppLayout>
      );
    }

    return (
      <AppLayout
        selectedCustomer={ selectedDeploymentGroup?.name ? selectedDeploymentGroup?.name : selectedDeploymentGroup?.id ?? "" }
        locale={ locale ?? "en" }
        swapLanguage={ this.swapLanguage }
      >
        { this.renderAddDeviceDialog () }
        { this.renderRemoveDeviceDialog () }
        { this.leftSideBar() }
        <Box 
          p={ 4 }
          className={ classes.content }
        >
          { this.renderDeviceInfo() }
          <Box display="flex">
            <Box height="400px" flex={ 1 } mr={ 4 }>
              { this.renderCommandSending() }
            </Box>
            <Box>
              { this.renderDeviceLocation() }
            </Box>
          </Box>
          
          { this.renderDeviceQueries() }
        </Box>
      </AppLayout>
    );
  }

  /**
   * Render left sidebar
   */
  private leftSideBar = () => {
    const { deploymentGroupId } = this.props
    const { loading, allDevices } = this.state;

    return (
      <SideBar
        loading={ loading }
        devices={ allDevices.filter(device => device.deploymentGroupId === deploymentGroupId) }
        addDialogToggle={ this.toggleAddDeviceDialog }
        updateHaltianData={ this.updateHaltianData }
        onDeviceClick={ this.onDeviceClick }
      />
    );
  }

  /**
   * Renders device info
   */
  private renderDeviceInfo = () => {

    return (
      <>
        <Box
          mb={ 4 }
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            display="flex"
            alignItems="center"
          >
            <Typography variant="h2" style={{ marginRight: theme.spacing(1) }}>
              { strings.customer.deviceInfo }
            </Typography>
            <Box ml={ 2 }>
              <Typography>
                { "//" }
              </Typography>
            </Box>
          </Box>

          <Box>
            <Button
              variant="text"
              onClick={ this.openRemoveDeviceDialog }
            >
              <CloseIcon></CloseIcon>
              <Box ml={ 1 }>
                { strings.customer.deleteDevice }
              </Box>
            </Button>
          </Box>
        </Box>
        <Paper>
          <Box display="flex" p={ 4 } flexDirection="column">
            { this.renderDeviceInfoContent() }
          </Box>
        </Paper>
      </>
    );
  }

  /**
   * Renders device info content block
   */
  private renderDeviceInfoContent = () => {
    const { classes } = this.props;
    const { selectedDevice } = this.state;

    if (!selectedDevice) {
      return null;
    }

    return (
      <>
        <div className={ classes.infoTopWrapper }>
          <Logo className={ classes.logo }/>
          <Box color="#828282">
            { selectedDevice?.deviceInfo?.lastUpdate &&
              <Typography variant="h5" color="inherit">
                { strings.customer.updated }
                { moment.unix(Number(selectedDevice?.deviceInfo?.lastUpdate)).format("DD.MM.YYYY HH:mm") }
              </Typography>
            }
            
          </Box>
        </div>
        <Box mt={ 4 }>
          <Typography variant="h2">
            { selectedDevice?.deviceInfo?.model ?? "" }
          </Typography>
        </Box>
        <div className={ classes.infoContainerId }>
          <Box mr={ 1 }>
            <Typography variant="h5" color="inherit">
              { strings.customer.id }
            </Typography>
          </Box>
          <Typography variant="h5" color="inherit">
            { selectedDevice?.id }
          </Typography>
        </div>
        <div className={ classes.infoContainerBlock }>
          <Typography variant="h4">
            { selectedDevice?.name ?? "" }
          </Typography>
        </div>
        <div className={ classes.infoContainerBlock }>
          <Box mr={ 1 }>
            <Typography variant="h2" >
              { strings.customer.battery }
            </Typography>
          </Box>
          <Typography variant="h4">
            { selectedDevice.deviceInfo?.battery ? `${ selectedDevice.deviceInfo.battery } %` : "? %"} 
          </Typography>
        </div>
      </>
    );
  }

  /**
   * Renders command sending
   * 
   * TODO: fetch device queries
   */
    private renderCommandSending = () => {
      return (
        <>
          <Box
            mt={ 4 }
            mb={ 4 }
            display="flex"
            alignItems="center"
          >
            <Typography variant="h2">
              { strings.customer.sendCommand }
            </Typography>
            <Box ml={ 2 }>
              <Typography>
                { "//" }
              </Typography>
            </Box>
          </Box>
          <Paper>
          <Box p={ 4 }>
            <Typography>
              { strings.comingSoon }
            </Typography>
          </Box>
        </Paper>
        </>
      );
    }

  /**
   * Renders device location
   */
  private renderDeviceLocation = () => {
    const { selectedDevice } = this.state;

    if (!selectedDevice) {
      return null;
    }

    return (
      <>
        <Box
          mt={ 4 }
          mb={ 4 }
          display="flex"
          alignItems="center"
        >
          <Typography variant="h2">
            { strings.customer.location }
          </Typography>
          <Box ml={ 2 }>
            <Typography>
              { "//" }
            </Typography>
          </Box>
        </Box>
          { this.renderMap() }
      </>
    );
  }

  /**
   * Renders map
   */
  private renderMap = () => {
    const { selectedDevice } = this.state;

    if (selectedDevice?.deviceInfo?.coordinates) {
      return (
        <Box
          width={ 400 }
          height={ 400 }
          key={ `${selectedDevice.id}` }
        >
          <Map
            latitude={ selectedDevice.deviceInfo?.coordinates?.latitude }
            longitude={ selectedDevice.deviceInfo?.coordinates?.longitude }
          />
        </Box>
      );
    }

    return (
      <Paper>
        <Box width={ 400 } height={ 400 }>
          <Typography>{ strings.customer.noDataAvailable }</Typography>
        </Box>
      </Paper>
    );
  }

  /**
   * Renders device queries
   * 
   * TODO: fetch device queries
   */
  private renderDeviceQueries = () => {
    return (
      <>
        <Box
          mt={ 4 }
          mb={ 4 }
          display="flex"
          alignItems="center"
        >
          <Typography variant="h2">
            { strings.customer.messageQueries }
          </Typography>
          <Box ml={ 2 }>
            <Typography>
              { "//" }
            </Typography>
          </Box>
        </Box>
        <Paper>
          <Box p={ 4 }>
            <Typography>
              { strings.comingSoon }
            </Typography>
          </Box>
        </Paper>
      </>
    );
  }

  /**
   * Renders add device dialog
   */
  private renderAddDeviceDialog = () => {
    const { addDeviceDialog } = this.state;

    return (
      <GenericDialog
        open={ addDeviceDialog }
        error={ false }
        onClose={ this.toggleAddDeviceDialog }
        title={ strings.customer.addDevices }
        positiveButtonText={ strings.customer.add }
        cancelButtonText={ strings.customer.cancel }
        onCancel={ this.toggleAddDeviceDialog }
        onConfirm={ this.onAddDeviceButtonClick }
      >
        { this.renderAddDeviceDialogContent() }
      </GenericDialog>
    );
  }

  /**
   * Renders content for add device dialog
   */
  private renderAddDeviceDialogContent = () => {
    const { allDevices, checkBoxedDevices } = this.state;

    const checkBoxes = allDevices.filter(device => (!device.deploymentGroupId || device.deploymentGroupId === "")).map(device => {
      return (
        <FormControlLabel
          label={ device.name }
          control={
            <Checkbox
              checked={ checkBoxedDevices.find(d => d.id === device.id) !== undefined }
              onChange={ this.onCheckboxChange }
              name={ device.id }
            />
          }
        />
      );
    });

    return (
      <FormGroup>
        { checkBoxes }
      </FormGroup>
    );
  }

  /**
   * Renders remove device dialog
   */
  private renderRemoveDeviceDialog = () => {
    const { removeDeviceDialog } = this.state;

    return (
      <GenericDialog
        open={ removeDeviceDialog }
        error={ false }
        onClose={ this.onRemoveDialogCloseClick }
        title={ strings.customer.deleteDevice + "?" }
        positiveButtonText={ strings.customer.yes }
        cancelButtonText={ strings.customer.cancel }
        onCancel={ this.onRemoveDialogCloseClick }
        onConfirm={ this.onRemoveDeviceButtonClick }
      >
        { this.renderRemoveDeviceDialogContent() }
      </GenericDialog>
    );
  }

  /**
   * Renders content for remove device dialog
   */
  private renderRemoveDeviceDialogContent = () => {

    return (
      <Typography variant="h4">
        { strings.customer.deleteDeviceConfirmText }
      </Typography>
    );
  }

  /**
   * Event handler for debug mode change
   *
   * @param event React change event
   * @param checked checkbox checked state
   */
  private onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const { allDevices } = this.state;
    const { name } = event.target;

    if (!name) {
      return;
    }

    const checkedDevice = allDevices.find(device => device.id === name);
    if (!checkedDevice) {
      return;
    }

    const tempDevices = [ ...this.state.checkBoxedDevices ] as Device[];

    const index = tempDevices.findIndex(device => device.id === name);
    if (index > -1) {
      this.setState({
        checkBoxedDevices: tempDevices.filter(device => device.id !== name)
      });
    } else {
      this.setState({
        checkBoxedDevices: [ ...tempDevices, checkedDevice ]
      });
    }

  }

  /**
   * Add device dialog close handler
   */
  private onRemoveDialogCloseClick = () => {
      this.setState({ removeDeviceDialog: false });
  };

  /**
   * Add device dialog toggle
   */
  private toggleAddDeviceDialog = () => {
    const { deploymentGroupId } = this.props;
    const { allDevices } = this.state;

    const checkBoxedDevices: Device[] = allDevices.filter(device => device.deploymentGroupId === deploymentGroupId);

    this.setState({ 
      addDeviceDialog: !this.state.addDeviceDialog,
      checkBoxedDevices: checkBoxedDevices
    });
  }

  /**
   * Add device dialog open handler
   */
  private openRemoveDeviceDialog = () => {
    this.setState({ 
      removeDeviceDialog: true 
    });
  }

  /**
   * Event handler for device click
   *
   * @param device clicked device
   */
  private onDeviceClick = async (device: Device) => {
    this.setState({
      selectedDevice: device
    });
  }

  /**
   * Event handler for add device button click
   */
  private onAddDeviceButtonClick = async () => {
    const { accessToken, deploymentGroupId } = this.props;
    const { checkBoxedDevices, selectedDeploymentGroup } = this.state;

    if (!selectedDeploymentGroup || !accessToken) {
      return;
    }

    try {
      const devicesApi = Api.getDevicesApi(accessToken);
      const thingsApi = HaltianApi.getThingsApi(accessToken.haltianToken);

      for (const checkBoxedDevice of checkBoxedDevices) {
        const tempDevice = { ...checkBoxedDevice };

        if (tempDevice && tempDevice.id) {
          tempDevice.deploymentGroupId = deploymentGroupId;

          await Promise.all<Device, ThingDeploymentGroupResponse>([
            devicesApi.updateDevice({
              deviceId: tempDevice.id,
              device: tempDevice
            }),
            thingsApi.setDeploymentGroup({
              thingId: tempDevice.id,
              thingDeploymentGroupRequest: {
                group_id: selectedDeploymentGroup.haltianId
              }
            })
          ]);
        }
      }
      this.setState({
        addDeviceDialog: false,
        checkBoxedDevices: []
      });

    } catch (error) {
      this.context.setError(strings.error.whenAddingDevice, error);
    }
  }

  /**
   * Event handler for remove device button click
   * 
   * @param device device to be removed from customer / deployment group
   */
  private onRemoveDeviceButtonClick = async () => {
    const { accessToken } = this.props;
    const { allDevices } = this.state;
    const tempDevice = { ...this.state.selectedDevice };

    if (!tempDevice || !tempDevice.id || !accessToken) {
      return;
    }

    try {
      tempDevice.deploymentGroupId = undefined;
      const updatedDevices = produce(allDevices, draft => {
        const deviceIndex = draft.findIndex(item => item.id === tempDevice.id);
        if (deviceIndex > -1) {
          draft.splice(deviceIndex, 1, tempDevice);
        }
      });

      const devicesApi = Api.getDevicesApi(accessToken);
      await devicesApi.updateDevice({
        device: tempDevice,
        deviceId: tempDevice.id
      });

      this.setState({
        removeDeviceDialog: false,
        selectedDevice: undefined,
        allDevices: updatedDevices
      });

    } catch (error) {
      this.context.setError(strings.error.whenDeletingDevice, error);
    }
  }

  /**
   * Provides language swap functionality for the page
   * 
   * @param locale locale
   */
  private swapLanguage = (locale: string) => {
    strings.setLanguage(locale);
    this.setState({ locale: locale });
  }

  /**
   * Fetch devices data from Haltian API and update in into Metatavu's API for faster access later
   */
  private updateHaltianData = async () => {
    const { accessToken, deploymentGroupId } = this.props;
    const { allDevices } = this.state;

    if (!accessToken) {
      return;
    }

    this.setState({ loading: true });

    const devicesApi = Api.getDevicesApi(accessToken);
    const thingsApi = HaltianApi.getThingsApi(accessToken.haltianToken);
    const updatedDevices: Device[] = [];
      
    for (const device of allDevices) {
      if (device.id && device.deploymentGroupId === deploymentGroupId) {
        const response = await thingsApi.generalInfo({ thingId: device.id });
        if (response.data) {
          const updatedDevice: Device = {
            ...device,
            deviceInfo: {
              model: response.data.version ?? "",
              coordinates: {
                latitude: response.data.location?.lat ? Number(response.data.location?.lat) : device.deviceInfo?.coordinates?.latitude ?? 0,
                longitude: response.data.location?.lon ? Number(response.data.location?.lon) : device.deviceInfo?.coordinates?.longitude ?? 0
              },
              battery: response.data.battery_level ?? 0,
              lastUpdate: response.data.timestamp ? response.data.timestamp.toString() : ""
            }
          };
          updatedDevices.push(updatedDevice);
          if (updatedDevice.id) {
            devicesApi.updateDevice({
              deviceId: updatedDevice.id,
              device: updatedDevice
            });
          }
        } else {
          updatedDevices.push(device);
        }
      } else {
        updatedDevices.push(device);
      }
    }

    this.setState({
      allDevices: updatedDevices,
      loading: false
    });
  }

  /**
   * Fetch devices data from API
   */
  private fetchData = async () => {
    const { accessToken } = this.props;
    const { deploymentGroupId } = this.props;

    if (!accessToken) {
      return;
    }

    this.setState({ loading: true });

    try {
      const devicesApi = Api.getDevicesApi(accessToken);
      const deploymentGroupsApi = Api.getDeploymentGroupsApi(accessToken);

      const [ allDevices, deploymentGroup ] = await Promise.all<Device[], DeploymentGroup>([
        devicesApi.listDevices({ }),
        deploymentGroupsApi.findDeploymentGroup({
          deploymentGroupId: deploymentGroupId
        })
      ]);

      this.setState({
        allDevices: allDevices,
        selectedDeploymentGroup: deploymentGroup,
        loading: false
      });
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 * @returns state from props
 */
const mapStateToProps = (state: ReduxState) => ({
  accessToken: state.auth.accessToken,
  keycloak: state.auth.keycloak as KeycloakInstance,
  locale: state.locale.locale
});

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
const mapDispatchToProps = (dispatch: Dispatch<ReduxActions>) => ({
  setLocale: (locale: string) => dispatch(setLocale(locale))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomerScreen));
